import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, Container } from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";
import { red } from "@material-ui/core/colors/";
import SignInForm from "../components/Login/SignInForm";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
  },
  iconContainer: {
    marginBottom: theme.spacing(2),
    height: "5rem",
    width: "5rem",
    backgroundColor: red[300],
  },
  icon: {
    fontSize: "50px",
  },
}));

function Login() {
  const classes = useStyles();
  
  return (
    <Container
      className={classes.container}
      component="main"
      maxWidth="xs"
      disableGutters
    >
      <Box
        justify="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Avatar className={classes.iconContainer}>
          <LockOutlined className={classes.icon} />
        </Avatar>
        <SignInForm />
      </Box>
    </Container>
  );
}

export default Login;
