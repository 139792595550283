import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const LoadingProgress = () => {
  const classes = useStyles();
  return <CircularProgress className={classes.root}/>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    left: "50%",
    top: "50%",
  }
}));

export default LoadingProgress;