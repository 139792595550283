import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

const TraderDashCard = ({title, value}) => {
  const classes = useStyles();
  
  return (
    <>
      <Card className={classes.container}>
        <CardContent>
          <Grid
            container
            direction="column"
            alignContent="center"
            justifyContent="center">
            <Grid item>
              <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid
              item
              className={classes.value}>
              <Typography variant="h6">{value}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

const useStyles = makeStyles({
  container: {
    minWidth: 200
  },
  value: {
    textAlign: "center"
  }
});

export default TraderDashCard;