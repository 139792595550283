import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControl,
  Grid, IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import DatePicker from "react-multi-date-picker";
import ButtonMain from "../../UI/Buttons/ButtonMain";
import { traderService } from "../../../services/traderService";
import { actionCrudVerb, userTypeConstant } from "../../../helpers/constants";
import { userService } from "../../../services/userService";
import { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";

const ActionCreate = ({toggleModalOpen}) => {
  const classes = useStyles();
  const [articles, setArticles] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const {userType} = userService.getCurrentUser();
  
  useEffect(() => {
    (async () => {
      const result = await traderService.getArticlesAsync();
      if (result.success) {
        setArticles(result.data);
      } else {
        console.log("kme");
      }
    })();
  }, []);
  
  useEffect(() => {
    console.log(selectedArticles);
  }, [selectedArticles]);
  
  const traderModels = [{id: 3, name: "Procenat po računu"}, {id: 4, name: "Fiksno po računu"}, {
    id: 5,
    name: "Procenat po artiklu"
  }, {id: 6, name: "Fiksno po artiklu"}];
  const distributorModels = [{id: 1, name: "Procenat po artiklu"}, {id: 2, name: "Fiksno po artiklu"}];
  
  const handleMultipleChange = (params) => {
    const {row} = params;
    
    const onClick = async (e) => {
      e.stopPropagation();
      console.log(row);
      const index = selectedArticles.findIndex((existingItem) => existingItem.ART_ID === row.ID);
      console.log(index);
      if (index > -1){
        const newArticles = selectedArticles.filter(article => article.ART_ID !== row.ID);
        setSelectedArticles(newArticles);
      } else {
        console.log(row);
        setSelectedArticles([...selectedArticles, {ART_ID: row.ID, POENI: parseInt(row.POENI)}])
      }
    };
  
    return (
      <IconButton onClick={onClick} disabled={row.POENI === undefined}>
        <Checkbox/>
      </IconButton>
    );
  };
  
  const columns = [
    {
      field: "NAZIV",
      headerName: "Naziv artikla",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      flex: 1
    },
    {
      field: "POENI",
      headerName: "Poeni",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      editable: true,
      flex: 1
    },
    {
      field: "izaberi",
      headerName: "Izaberi",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: handleMultipleChange
    }
  ];
  
  const formik = useFormik({
    initialValues: {
      NazivAkcije: "",
      datum_od: new Date(),
      datum_do: new Date(),
      model: undefined,
      Poeni: null,
      AkcijaID: 0
    },
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      if ((formik.values.model === 5 || formik.values.model === 6) && selectedArticles.length > 0) {
        values.Artikli = selectedArticles;
      }
      if (formik.values.model === 5 || formik.values.model === 6) {
        delete values.Poeni;
      }
      const result = await traderService.createUpdateDeleteActionAsync(values, actionCrudVerb.CREATE);
      if (result.success) {
        console.log("crud success");
        window.location.reload();
      } else {
        console.log("crud action failed");
        formik.setSubmitting(false);
      }
    },
    validationSchema: () =>
      yup.lazy((value) => {
        console.log("FORMIK LAZY",value);
        return yup.object({
          NazivAkcije: yup.string().required("Neophodno polje"),
          datum_od: yup.date().required("Neophodno polje"),
          datum_do: yup.date().required("Neophodno polje"),
          model: yup.number().typeError("Mora biti broj").required("Neophodno polje"),
          Poeni: (value.model === 3 || value.model === 4)
            ? yup.number().required("Neophodno polje").typeError("Mora biti broj")
            : yup.number().nullable().notRequired(),
        });
      }),
  });
  
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        className={classes.root}
        justifyContent='space-between'
        direction="column">
        <Grid>
          <Grid
            item
            className={classes.textField}>
            <TextField
              fullWidth
              id="NazivAkcije"
              label="Naziv akcije"
              value={formik.values.NazivAkcije}
              onChange={(event) => {
                formik.setFieldValue("NazivAkcije", event.target.value);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.NazivAkcije &&
                Boolean(formik.errors.NazivAkcije)
              }
              helperText={
                formik.touched.NazivAkcije && formik.errors.NazivAkcije
              }/>
          </Grid>
          {(formik.values.model === 3 || formik.values.model === 4) &&
            <Grid
              item
              className={classes.textField}>
              <TextField
                fullWidth
                id="Poeni"
                label="Poeni"
                value={formik.values.Poeni}
                onChange={(event) => {
                  formik.setFieldValue("Poeni", event.target.value);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.Poeni &&
                  Boolean(formik.errors.Poeni)
                }
                helperText={
                  formik.touched.Poeni && formik.errors.Poeni
                }/>
            </Grid>}
          <Grid
            item
            className={classes.textField}>
            <FormControl fullWidth>
              <Select
                native={false}
                displayEmpty
                labelId="model"
                id="model"
                value={formik.values.model}
                label="model"
                onChange={(event) => {
                  formik.setFieldValue("model", event.target.value);
                }}
                renderValue={formik.values.model ? undefined : () => "Model"}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}>
                {userType === userTypeConstant.TRADER ? traderModels.map((item) => (
                    <MenuItem
                      value={item.id}
                      key={item.id}>
                      {item.name}
                    </MenuItem>
                  )) :
                  distributorModels.map((item) => (
                    <MenuItem
                      value={item.id}
                      key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start">
            <Grid
              item
              direction="column"
              className={classes.calendarSeparator}>
              <Grid
                item
                className={classes.calendarLabel}>
                <InputLabel>Početak akcije</InputLabel>
              </Grid>
              <DatePicker
                value={formik.values.datum_od}
                className={classes.datePicker}
                layout='mobile'
                onChange={(date) => {
                  formik.setFieldValue("datum_od", date);
                }}
                type="button"/>
            </Grid>
            <Grid
              item
              direction="column">
              <Grid
                item
                className={classes.calendarLabel}>
                <InputLabel>Kraj akcije</InputLabel>
              </Grid>
              <DatePicker
                value={formik.values.datum_do}
                onChange={(date) => {
                  formik.setFieldValue("datum_do", date);
                }}
                type="button"/>
            </Grid>
          </Grid>
          <Grid
            item
            className={classes.table}>
            {(formik.values.model === 5 || formik.values.model === 6) &&
              <div>
                <InputLabel className={classes.calendarLabel}>Artikli</InputLabel>
                <DataGrid
                  className={classes.tableRoot}
                  columns={columns}
                  rows={articles}
                  // onSelectionModelChange={ handleRowSelection }
                  getRowHeight={() => "auto"}
                  // pageSize={10}
                  // checkboxSelection
                  disableSelectionOnClick
                  disableColumnMenu
                  autoHeight/>
              </div>}
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          justifyContent="flex-end">
          <Grid item>
            <ButtonMain
              title="Odustani"
              onClick={toggleModalOpen}/>
          </Grid>
          <Grid item>
            <ButtonMain
              title="Napravi"
              onClick={formik.handleSubmit}
              disabled={!(formik.isValid && formik.dirty && !formik.isSubmitting)}/>
          </Grid>
        </Grid>
      </Grid>
    </form>);
};

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    marginBottom: "1rem"
  },
  calendarSeparator: {
    marginRight: "2rem"
  },
  calendarLabel: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem"
  },
  buttonSeparator: {
    marginRight: "2rem"
  },
  table: {
    minWidth: "100%",
    marginBottom: "2rem",
    marginTop: "1rem",
  },
  datePicker: {
    overflowY: 'scroll'
  },
  root: {
    minHeight: '500px',
    minWidth: '500px',
  }
}));

export default ActionCreate;