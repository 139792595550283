import axiosClient from "../apiClient";

const getStoreDash = (token) => {
  return axiosClient.get("getStoreDash", {
    headers: {
      "Token": token
    }
  });
};

const getStoreActions = (token) => {
  return axiosClient.get("getStoreAkcije", {
    headers: {
      "Token": token
    }
  });
};

const getStoreActionById = (id, token) => {
  return axiosClient.get("getStoreAkcijeByID", {
    headers: {
      "Token": token,
      "AkcijaID": id
    }
  });
};

const createUpdateDeleteAction = (token, data) => {
  return axiosClient.post("setStoreAkcija", data, {
    headers: {
      "Token": token
    }
  });
};

const getArticles = (token) => {
  return axiosClient.get("getStoreArtikli", {
    headers: {
      "Token": token
    }
  });
}

export const traderApi = {getStoreDash, getStoreActions, getStoreActionById, createUpdateDeleteAction, getArticles};