import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import ButtonMain from "../../UI/Buttons/ButtonMain";
import { DataGrid } from "@material-ui/data-grid";
import LoadingProgress from "../../UI/LoadingProgress";
import { useEffect, useState } from "react";
import { traderService } from "../../../services/traderService";

const ArticlesOverview = () => {
  const classes = useStyles();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    (async () => {
      const result = await traderService.getArticlesAsync();
      if (result.success) {
        setArticles(result.data);
      } else {
        console.log("kme");
      }
      setLoading(false);
    })();
  }, []);
  
  const columns = [
    {
      field: "NAZIV",
      headerName: "Naziv artikla",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      flex: 1
    },
    {
      field: "BRENDNAZIV",
      headerName: "Naziv brenda",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      flex: 1
    },
    {
      field: "ROBNAGRUPA",
      headerName: "Robna grupa",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      flex: 1
    },
    {
      field: "EAN",
      headerName: "EAN",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      flex: 1
    },
  ];
  
  if (loading) {
    return <LoadingProgress/>;
  }
  
  return (
    <>
      <Typography variant="h4">Artikli</Typography>
      {articles.length === 0 && <Typography
        variant="h6"
        className={classes.sectionTitle}>Nema artikala</Typography>}
      <Grid
        container
        className={classes.tableContainer}>
        <Grid
          item
          className={classes.table}>
          {articles.length > 0 &&
            <DataGrid
              className={classes.tableRoot}
              columns={columns}
              rows={articles}
              // onSelectionModelChange={ handleRowSelection }
              getRowHeight={() => "auto"}
              // pageSize={10}
              // checkboxSelection
              disableSelectionOnClick
              disableColumnMenu
              autoHeight/>}
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginBottom: "1rem",
    marginTop: "1rem"
  },
  tableContainer: {
    // maxWidth: '70%'
  },
  table: {
    minWidth: "100%",
    marginTop: "2rem"
  },
  tableRoot: {
    "& .MuiDataGrid-renderingZone": {
      backgroundColor: theme.palette.background.paper
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.background.paper
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: theme.palette.background.paper
    }
  }
}));

export default ArticlesOverview;