import { makeStyles } from "@material-ui/core/styles";
import { userService } from "../../../services/userService";
import { useFormik } from "formik";
import { traderService } from "../../../services/traderService";
import { actionCrudVerb, userTypeConstant } from "../../../helpers/constants";
import * as yup from "yup";
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import DatePicker from "react-multi-date-picker";
import ButtonMain from "../../UI/Buttons/ButtonMain";
import { Delete } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";

const ActionEdit = ({toggleModalOpen, action}) => {
  const classes = useStyles();
  const {userType} = userService.getCurrentUser();
  const [currentArticles, setCurrentArticles] = useState([])
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedArticles, setSelectedArticles] = useState([]);
  
  useEffect(() => {
    (async () => {
      console.log(action)
      const actionResult = await traderService.getStoreActionByIdAsync(action.AkcijaID);
      const articleResult = await traderService.getArticlesAsync();
      if (actionResult.success) {
        setCurrentArticles(actionResult.data.Artikli);
      } else {
        console.log("kme");
      }
      
      if (action.model === 5 || action.model === 6) {
        const articleResult = await traderService.getArticlesAsync();
        if (articleResult.success) {
          setArticles(articleResult.data);
        } else {
          console.log("kme");
        }
      }
      setLoading(false);
    })();
  }, []);
  
  const traderModels = [{id: 3, name: "Procenat po računu"}, {id: 4, name: "Fiksno po računu"}, {
    id: 5,
    name: "Procenat po artiklu"
  }, {id: 6, name: "Fiksno po artiklu"}];
  const distributorModels = [{id: 1, name: "Procenat po artiklu"}, {id: 2, name: "Fiksno po artiklu"}];
  
  const formik = useFormik({
    initialValues: {
      NazivAkcije: action.NazivAkcije,
      datum_od: action.datum_od,
      datum_do: action.datum_do,
      model: action.model,
      Status: action.Status,
      AkcijaID: action.AkcijaID
    },
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      if ((formik.values.model === 5 || formik.values.model === 6) && selectedArticles.length > 0) {
        values.Artikli = selectedArticles;
      }
      const result = await traderService.createUpdateDeleteActionAsync(values, actionCrudVerb.UPDATE);
      if (result.success) {
        console.log("crud success");
        window.location.reload();
      } else {
        console.log("crud action failed");
        formik.setSubmitting(false);
      }
    },
    validationSchema: yup.object({
      NazivAkcije: yup
      .string()
      .required("Neophodno polje"),
      datum_od: yup
      .date()
      .required("Neophodno polje"),
      datum_do: yup
      .date()
      .required("Neophodno polje"),
      model: yup
      .number()
      .typeError("Mora biti broj")
      .required("Neophodno polje")
    })
  });
  
  const handleDeleteArticle = (params) => {
    const {row} = params;
    const onClick = async (e) => {
      console.log(row);
      e.stopPropagation();
      row.Poeni = 0;
      action.Artikli = row;
      const result = await traderService.createUpdateDeleteActionAsync(action, actionCrudVerb.UPDATE);
      if (result.success) {
        const newArticles = currentArticles.filter(article => article.ART_ID !== row.ART_ID);
        console.log(newArticles);
        setCurrentArticles(newArticles);
      }
    };
    
    return (
      <IconButton onClick={onClick}>
        <Delete/>
      </IconButton>
    );
  };
  
  const handleAddArticle = (params) => {
    const {row} = params;
    
    const onClick = async (e) => {
      e.stopPropagation();
      console.log(row);
      const index = selectedArticles.findIndex((existingItem) => existingItem.ART_ID === row.ID);
      console.log(index);
      if (index > -1){
        const newArticles = selectedArticles.filter(article => article.ART_ID !== row.ID);
        setSelectedArticles(newArticles);
      } else {
        setSelectedArticles([...selectedArticles, {ART_ID: row.ID, POENI: parseInt(row.POENI)}])
      }
    };
    
    return (
      <IconButton onClick={onClick} disabled={row.POENI === undefined}>
        <Checkbox/>
      </IconButton>
    );
  };
  
  const currentColums = [
    {
      field: "ART_NAZIV",
      headerName: "Naziv artikla",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      flex: 1
    },
  
    {
      field: "POENI",
      headerName: "Poeni",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      flex: 1
    },
    {
      field: "obrisi",
      headerName: "Obriši",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: handleDeleteArticle
    }
  ];
  const allColums = [
    {
      field: "NAZIV",
      headerName: "Naziv artikla",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      flex: 1
    },
    {
      field: "POENI",
      headerName: "Poeni",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      editable: true,
      flex: 1
    },
    {
      field: "Izaberi",
      headerName: "Izaberi",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: handleAddArticle
    }
  ];
  
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        direction="row">
        <Grid
          item
          className={classes.textField}>
          <TextField
            fullWidth
            id="NazivAkcije"
            label="Naziv akcije"
            value={formik.values.NazivAkcije}
            onChange={(event) => {
              formik.setFieldValue("NazivAkcije", event.target.value);
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.NazivAkcije &&
              Boolean(formik.errors.NazivAkcije)
            }
            helperText={
              formik.touched.NazivAkcije && formik.errors.NazivAkcije
            }/>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start">
          <Grid
            item
            direction="column"
            className={classes.calendarSeparator}>
            <Grid
              item
              className={classes.calendarLabel}>
              <InputLabel>Početak akcije</InputLabel>
            </Grid>
            <DatePicker
              value={formik.values.datum_od}
              onChange={(date) => {
                formik.setFieldValue("datum_od", date);
              }}
              type="button"/>
          </Grid>
          <Grid
            item
            direction="column">
            <Grid
              item
              className={classes.calendarLabel}>
              <InputLabel>Kraj akcije</InputLabel>
            </Grid>
            <DatePicker
              value={formik.values.datum_do}
              onChange={(date) => {
                formik.setFieldValue("datum_do", date);
              }}
              type="button"/>
          </Grid>
        </Grid>
        {currentArticles?.length > 0 &&
          <Grid
            item
            container
            className={classes.info}>
            <InputLabel className={classes.calendarLabel}>Trenutni artikli u akciji</InputLabel>
            <DataGrid
              className={classes.table}
              columns={currentColums}
              rows={currentArticles}
              getRowHeight={ () => "auto" }
              pageSize={ 10 }
              disableSelectionOnClick
              disableColumnMenu
              autoHeight/>
          </Grid>}
        {articles?.length > 0 &&
          <Grid
            item
            container>
            <InputLabel className={classes.calendarLabel}>Svi artikli</InputLabel>
            <DataGrid
              className={classes.table}
              columns={allColums}
              rows={articles}
              getRowHeight={ () => "auto" }
              pageSize={ 10 }
              disableSelectionOnClick
              disableColumnMenu
              autoHeight/>
          </Grid>}
        <Grid
          item
          container
          spacing={1}
          justifyContent="flex-end">
          <Grid item>
            <ButtonMain
              title="Odustani"
              onClick={toggleModalOpen}/>
          </Grid>
          <Grid item>
            <ButtonMain
              title="Izmeni"
              onClick={formik.handleSubmit}/>
          </Grid>
        </Grid>
      </Grid>
    </form>);
};

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    marginBottom: "1rem"
  },
  calendarSeparator: {
    marginRight: "2rem"
  },
  calendarLabel: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem"
  },
  buttonSeparator: {
    marginRight: "2rem"
  },
  table: {
    minWidth: "100%",
    marginBottom: "2rem",
    marginTop: "1rem",
  },
  info: {
    marginTop: "1rem"
  },
}));

export default ActionEdit;