import Navbar from "../components/UI/Navigation/Navbar";
import PageWrapper from "../components/UI/PageWrapper";
import ActionsOverview from "../components/Trader/Actions/ActionsOverview";

function Actions() {
  return (
    <>
      <Navbar/>
      <PageWrapper>
        <ActionsOverview />
      </PageWrapper>
    </>
  );
}

export default Actions;