import { Route, Redirect } from "react-router-dom";
import { userService } from "../services/userService";

function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = userService.isLoggedIn();
  
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
}

export default PrivateRoute;