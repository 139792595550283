import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const ButtonMain = ({title, onClick, disabled}) => {
  const classes = useStyles();
  return <>
    <Button
      className={disabled ? `${classes.root} ${classes.disabled}` : classes.root}
      disabled={disabled}
      onClick={onClick}>{title}</Button>
  </>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.text.primary
    }
  },
  disabled: {
    backgroundColor: theme.palette.grey["400"],
  }
}));

export default ButtonMain;