import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import LoadingProgress from "../../UI/LoadingProgress";
import { useEffect, useState } from "react";
import { traderService } from "../../../services/traderService";
import ButtonMain from "../../UI/Buttons/ButtonMain";
import { Delete, Edit, Visibility } from "@material-ui/icons";
import PopupModal from "../../UI/PopupModal";
import ActionDetails from "./ActionDetails";
import ActionCreate from "./ActionCreate";
import { actionCrudVerb } from "../../../helpers/constants";
import ActionEdit from "./ActionEdit";

const ActionsOverview = () => {
  const classes = useStyles();
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedActionId, setSelectedActionId] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  
  useEffect(() => {
    (async () => {
      const result = await traderService.getStoreActionsAsync();
      if (result.success) {
        setActions(result.data);
      } else {
        console.log("kme");
      }
      setLoading(false);
    })();
  }, []);
  
  const toggleCreateModalOpen = () => {
    setCreateModalOpen(!createModalOpen);
  };
  
  const toggleDetailsModalOpen = () => {
    setDetailsModalOpen(!detailsModalOpen);
  };
  
  const toggleEditModalOpen = () => {
    setEditModalOpen(!editModalOpen);
  };
  
  const toggleDeleteModalOpen = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };
  
  const handleDetailsAction = (params) => {
    const {row} = params;
    
    const onClick = (e) => {
      e.stopPropagation();
      setSelectedActionId(row.AkcijaID);
      toggleDetailsModalOpen();
    };
    
    return (
      <IconButton onClick={onClick}>
        <Visibility/>
      </IconButton>
    );
  };
  
  const handleEditAction = (params) => {
    const {row} = params;
    
    const onClick = (e) => {
      e.stopPropagation();
      setSelectedAction(row);
      toggleEditModalOpen();
    };
    
    return (
      <IconButton onClick={onClick}>
        <Edit/>
      </IconButton>
    );
  };
  
  const handleDeleteAction = (params) => {
    const {row} = params;
    
    const onClick = async (e) => {
      e.stopPropagation();
      // setSelectedActionId(row.AkcijaID);
      // toggleDeleteModalOpen();
      console.log(row);
      const result = await traderService.createUpdateDeleteActionAsync(row, actionCrudVerb.DELETE);
      if (result.success) {
        window.location.reload();
      } else {
        console.log("failed deleting");
      }
    };
    
    return (
      <IconButton onClick={onClick}>
        <Delete/>
      </IconButton>
    );
  };
  
  const columns = [
    {
      field: "NazivAkcije",
      headerName: "Naziv akcije",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      flex: 1
    },
    {
      field: "datum_od",
      headerName: "Od",
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1
    },
    {
      field: "datum_do",
      headerName: "Do",
      headerAlign: "center",
      align: "center",
      sortable: true,
      flex: 1
    },
    {
      field: "model",
      headerName: "Model",
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1
    },
    {
      field: "NazivModela",
      headerName: "Naziv modela",
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1
    },
    {
      field: "Status",
      headerName: "Status",
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1
    },
    {
      field: "Poeni",
      headerName: "Poeni",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center"
      // renderCell: handleEditFile
    },
    {
      field: "detalji",
      headerName: "Detalji",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: handleDetailsAction
    },
    {
      field: "izmeni",
      headerName: "Izmeni",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: handleEditAction
    },
    {
      field: "izbrisi",
      headerName: "Izbriši",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: handleDeleteAction
    }
  ];
  
  if (loading) {
    return <LoadingProgress/>;
  }
  
  return (
    <>
      <Typography variant="h4">Akcije</Typography>
      {actions.length === 0 && <Typography
        variant="h6"
        className={classes.sectionTitle}>Nema akcija</Typography>}
      <Grid
        container
        className={classes.tableContainer}>
        <Grid
          item
          container
          justifyContent="flex-end">
          <ButtonMain
            title="Napravi akciju"
            onClick={toggleCreateModalOpen}/>
        </Grid>
        <Grid
          item
          className={classes.table}>
          {actions.length > 0 &&
            <DataGrid
              className={classes.tableRoot}
              columns={columns}
              rows={actions}
              // onSelectionModelChange={ handleRowSelection }
              getRowHeight={() => "auto"}
              // pageSize={10}
              // checkboxSelection
              disableSelectionOnClick
              disableColumnMenu
              autoHeight/>}
        </Grid>
      </Grid>
      <PopupModal
        id={selectedActionId}
        title={"Akcija"}
        modalOpen={detailsModalOpen}
        toggleModalOpen={toggleDetailsModalOpen}>
        {(props) => <ActionDetails {...props} />}
      </PopupModal>
      <PopupModal
        title={"Nova akcija"}
        modalOpen={createModalOpen}
        toggleModalOpen={toggleCreateModalOpen}>
        {(props) => <ActionCreate {...props} />}
      </PopupModal>
      <PopupModal
        title={"Izmeni akciju"}
        modalOpen={editModalOpen}
        action={selectedAction}
        toggleModalOpen={toggleEditModalOpen}>
        {(props) => <ActionEdit {...props} />}
      </PopupModal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginBottom: "1rem",
    marginTop: "1rem"
  },
  tableContainer: {
    // maxWidth: '70%'
  },
  table: {
    minWidth: "100%",
    marginTop: "2rem"
  },
  tableRoot: {
    "& .MuiDataGrid-renderingZone": {
      backgroundColor: theme.palette.background.paper
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.background.paper
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: theme.palette.background.paper
    }
  }
}));

export default ActionsOverview;