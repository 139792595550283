import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Error from "./pages/Error"
import PrivateRoute from "./helpers/PrivateRoute";
import Actions from "./pages/Actions";
import Articles from "./pages/Articles";
import AnonymousRoute from "./helpers/AnonymousRoute";

function App() {
  return (
    <Router>
      <Switch>
        <AnonymousRoute exact path="/login" component={Login} />
        <Route exact path="/" component={Home}>
          <Redirect to="/home" />
        </Route>
        <PrivateRoute exact component={Home} path="/home" />
        <PrivateRoute exact component={Actions} path="/actions" />
        <PrivateRoute exact component={Articles} path="/articles" />
        <PrivateRoute component={Error} path="*" />
      </Switch>
    </Router>
  );
}

export default App;
