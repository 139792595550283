import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Grid, Tab, Tabs, Toolbar, Typography } from "@material-ui/core";
import { Link, useLocation, useHistory  } from "react-router-dom";
import { useState } from "react";
import { userService } from "../../../services/userService";

function Navbar() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const currentUser = userService.getCurrentUser();
  
  const getCurrentTab = () => {
    switch (location.pathname) {
      case "/":
      case "/home":
        return 0;
      case "/actions":
        return 1;
      case "/articles":
        return 2;
      // case "/employees":
      //   return 3;
    }
  };
  
  const [tabsValue, setTabsValue] = useState(getCurrentTab());
  
  const handleTabChange = (event, index) => {
    setTabsValue(index);
  };
  
  const handleLogout = async () => {
    const result = await userService.logoutAsync();
    if (result.success) {
      history.push("/login");
    } else {
      console.log("logout failed");
    }
  }
  
  return (
    <AppBar
      position="fixed"
      elevation={0}>
      <Toolbar
        className={classes.toolbar}
        classes={{gutters: classes.toolbarPadding}}>
        <Typography
          variant="h6"
          className={classes.logoLarge}>
          Loyalty
        </Typography>
        <Grid
          container
          justifyContent="space-between"
          className={classes.tabContainer}>
          <Grid item>
            <Tabs
              value={tabsValue}
              onChange={handleTabChange}
              indicatorColor={""}>
              <Tab
                label="Početna"
                component={Link}
                to="/"
                className={classes.tab}/>
              <Tab
                label="Akcije"
                component={Link}
                to="/actions"
                className={classes.tab}/>
              <Tab
                label="Artikli"
                component={Link}
                to="/articles"
                className={classes.tab}/>
            </Tabs>
          </Grid>
          <Grid item container className={classes.tabsRight} justifyContent='center' direction='column' alignItems='center'>
            <Grid item container>
              <Grid item>
                <Typography>{currentUser.ime}</Typography>
              </Grid>
              <Grid item>
                <Typography>{` , ${currentUser.firma}`} </Typography>
              </Grid>
              {[...Array(7)].map((x, i) =>
                <Grid item>
                  <Typography>&nbsp;</Typography>
                </Grid>
              )}
              <Grid item className={classes.logout} onClick={handleLogout}>
                <Typography>Izloguj se</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between"
  },
  logoLarge: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  tabContainer: {
    marginLeft: "2rem",
  },
  tab: {
    fontSize: "1rem",
    minWidth: "3rem",
    alignItems: "flex-end"
  },
  tabsRight: {
    width: 'auto'
  },
  toolbarPadding: {
    paddingLeft: "1rem",
    paddingRight: "1rem"
  },
  logout: {
    '&:hover': {
      cursor: 'pointer'
    },
  }
}));

export default Navbar;
