import { traderApi } from "../api/endpoints/traderApi";
import { webResult } from "../helpers/webResult";
import { userService } from "./userService";
import { actionCrudVerb } from "../helpers/constants";

const getStoreDashAsync = async () => {
  try {
    const { token } = userService.getCurrentUser();
    const response = await traderApi.getStoreDash(token);
    console.log(response);
    return webResult(true, JSON.parse(response.data));
  } catch (e) {
    console.log(e);
    return webResult(false);
  }
};

const getStoreActionsAsync = async () => {
  try {
    const { token } = userService.getCurrentUser();
    const response = await traderApi.getStoreActions(token);
    let actions = JSON.parse(response.data);
  
    const dataGridRows = actions.map((action) => ({
      ...action,
      id: action.AkcijaID,
    }));
    
    return webResult(true, dataGridRows);
  } catch (e) {
    return webResult(false);
  }
}

const getStoreActionByIdAsync = async (id) => {
  try {
    const { token } = userService.getCurrentUser();
    const response = await traderApi.getStoreActionById(id, token);
    let action = JSON.parse(response.data);
    action = action.Akcija[0];
    
    if (action.Artikli) {
      const tableArticles = action.Artikli.map((article) => ({
        ...article,
        id: article.ART_ID,
      }));
      action.Artikli = tableArticles;
    }
    
    return webResult(true, action);
  } catch (e) {
    return webResult(false);
  }
}

const createUpdateDeleteActionAsync = async (data, actionCrudVerb = null) => {
  try {
    const { token } = userService.getCurrentUser();
    
    switch (actionCrudVerb) {
      case 1:
        data.AkcijaID = 0;
        break;
      case 3:
        console.log("delete");
        delete data.Status;
        break;
      default:
        break;
    }
    
    data.datum_od = new Date(data.datum_od).toISOString().split('T')[0];
    data.datum_do = new Date(data.datum_do).toISOString().split('T')[0];
    
    const dataToSend = {
      "Akcija": [
        {...data}
      ]
    };
    console.log(dataToSend);
    const response = await traderApi.createUpdateDeleteAction(token, JSON.stringify(dataToSend));
    console.log(response);
    return webResult(true);
  } catch (e) {
    return webResult(false);
  }
}

const getArticlesAsync = async () => {
  try {
    const { token } = userService.getCurrentUser();
    const response = await traderApi.getArticles(token);
    const tableArticles = JSON.parse(response.data).map((article) => ({
      ...article,
      id: article.ID,
    }));
    return webResult(true, tableArticles);
  } catch (e) {
    return webResult(false);
  }
}

export const traderService = { getStoreDashAsync, getStoreActionsAsync, getStoreActionByIdAsync, createUpdateDeleteActionAsync, getArticlesAsync };