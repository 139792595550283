import { userService } from "../services/userService";
import { Redirect, Route, useLocation } from "react-router-dom";

function AnonymousRoute({component: Component, ...rest}) {
  const location = useLocation();
  const isAuthenticated = userService.isLoggedIn();
  
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect to={{pathname: "/"}}/>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default AnonymousRoute;