import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors/";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { userService } from "../../services/userService";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(5),
  },
  link: {
    marginTop: theme.spacing(1),
  },
  selectFormControl: {
    width: "100%",
  },
  formControlError: {
    color: theme.palette.error.main,
  },
}));

function SignInForm({ setIsSignIn }) {
  const classes = useStyles();
  const history = useHistory();
  const [showAuthError, setShowAuthError] = useState(false);
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async (values) => {
      const result = await userService.loginAsync(values);
      if (result.success) {
        history.replace("/home");
      } else {
        formik.setSubmitting(false);
        setShowAuthError(true);
      }
    },
    validationSchema: yup.object({
      username: yup
        .string()
        .required("Neophodno polje"),
      password: yup
        .string()
        .required("Neophodno polje"),
    }),
  });
  
  return (
    <>
      <Typography variant="h5">Prijava</Typography>
      <Box component="form" noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="username"
              label="Korisničko ime"
              name="username"
              value={formik.values.username}
              onChange={(event) => {
                formik.setFieldValue("username", event.target.value);
                setShowAuthError(false);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.username &&
                Boolean(formik.errors.username)
              }
              helperText={
                formik.touched.username && formik.errors.username
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="password"
              label="Lozinka"
              type="password"
              id="password"
              autoComplete="new-password"
              value={formik.values.password}
              onChange={(event) => {
                formik.setFieldValue("password", event.target.value);
                setShowAuthError(false);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
        </Grid>
        <Button
          color="primary"
          type="submit"
          fullWidth
          variant="contained"
          className={classes.button}
          disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
        >
          Prijavite se
        </Button>
        {showAuthError && (
          <FormHelperText className={classes.formControlError}>
            Pogrešno korisničko ime ili lozinka
          </FormHelperText>
        )}
      </Box>
    </>
  );
}

export default SignInForm;
