import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../components/UI/PageWrapper";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Navbar from "../components/UI/Navigation/Navbar";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.grey["900"],
  },
}));

function Error() {
  const classes = useStyles();
  return (
    <>
      <Navbar />
      <PageWrapper>
        <Typography variant="h3" color="error">
          Greška
        </Typography>
        <Typography variant="h5" color="error">
          Nešto ne radi :)
        </Typography>
        <Typography variant="p" color="textSecondary">
          Vratite se na{" "}
          <Link to="home" className={classes.link}>
            početnu
          </Link>{" "}
          stranicu.
        </Typography>
      </PageWrapper>
    </>
  );
}

export default Error;
