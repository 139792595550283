import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: "5rem",
    marginRight: "5rem",
    marginTop: "5rem",
    // overflowX: "hidden",
    // overflowY: "hidden",
    [theme.breakpoints.down("xs")]: {
      marginRight: "1rem",
      marginLeft: "4.5rem",
    },
  },
}));

function PageWrapper({ children }) {
  const classes = useStyles();
  return (
    <>
      <Box component="main" className={classes.container}>
        {/*<Grid container direction="row" justifyContent="space-between">*/}
              {children}
          {/*<Grid item xs={12} md={9}>*/}
          {/*</Grid>*/}
        {/*</Grid>*/}
      </Box>
    </>
  );
}

export default PageWrapper;
