import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { traderService } from "../../../services/traderService";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import ButtonMain from "../../UI/Buttons/ButtonMain";

const ActionDetails = ({id, toggleModalOpen}) => {
  const classes = useStyles();
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    (async () => {
      const result = await traderService.getStoreActionByIdAsync(id);
      if (result.success) {
        setAction(result.data);
      } else {
        console.log("kme");
      }
      setLoading(false);
    })();
  }, []);
  
  const columns = [
    {
      field: "ART_NAZIV",
      headerName: "Naziv artikla",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      flex: 1
    },
    {
      field: "BREND",
      headerName: "Brend",
      sortable: true,
      headerAlign: "center",
      align: "center",
      flex: 1
    },
    {
      field: "ROBNAGRUPA",
      headerName: "Robna grupa",
      headerAlign: "center",
      align: "center",
      sortable: true,
      flex: 1
    },
    {
      field: "POENI",
      headerName: "Poeni",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center"
      // renderCell: handleEditFile
    }
  ];
  
  if (loading) {
    return <CircularProgress/>;
  }
  
  return <>
    <Grid
      container
      direction="column">
      <Grid
        item
        className={classes.info}>
        <Typography variant="h6">
          Naziv
        </Typography>
        <Typography>
          {action?.NazivAkcije}
        </Typography>
      </Grid>
      <Grid
        item
        className={classes.info}>
        <Typography variant="h6">
          Od
        </Typography>
        <Typography>
          {action?.datum_od}
        </Typography>
      </Grid>
      <Grid
        item
        className={classes.info}>
        <Typography variant="h6">
          Do
        </Typography>
        <Typography>
          {action?.datum_do}
        </Typography>
      </Grid>
      <Grid
        item
        className={classes.info}>
        <Typography variant="h6">
          Model
        </Typography>
        <Typography>
          {action?.model}
        </Typography>
      </Grid>
      <Grid
        item
        className={classes.info}>
        <Typography variant="h6">
          Naziv modela
        </Typography>
        <Typography>
          {action?.NazivModela}
        </Typography>
      </Grid>
      <Grid
        item
        className={classes.info}>
        <Typography variant="h6">
          Status
        </Typography>
        <Typography>
          {action?.Status}
        </Typography>
      </Grid>
      {action?.Poeni &&
        <Grid
          item
          className={classes.info}>
          <Typography variant="h6">
            Poeni
          </Typography>
          <Typography>
            {action?.Poeni}
          </Typography>
        </Grid>}
      {action?.Artikli &&
        <Grid
          item
          className={classes.info}>
          <Typography variant="h6">
            Artikli
          </Typography>
          <DataGrid
            className={classes.table}
            columns={columns}
            rows={action.Artikli}
            getRowHeight={ () => "auto" }
            pageSize={ 10 }
            disableSelectionOnClick
            disableColumnMenu
            autoHeight/>
        </Grid>}
      <Grid item container justifyContent='flex-end'>
        <ButtonMain title='Zatvori' onClick={toggleModalOpen}/>
      </Grid>
    </Grid>
  </>;
};

const useStyles = makeStyles((theme) => ({
  info: {
    marginBottom: "1rem"
  },
  table: {
    minWidth: 700,
    marginTop: '1rem'
  },
}));

export default ActionDetails;