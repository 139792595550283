import { userService } from "../services/userService";
import { userTypeConstant } from "../helpers/constants";
import TraderOverview from "../components/Trader/TraderOverview";
import DistributorOverview from "../components/Distributor/DistributorOverview";
import Navbar from "../components/UI/Navigation/Navbar";
import PageWrapper from "../components/UI/PageWrapper";

const Home = () => {
  const { userType } = userService.getCurrentUser();
  
  return <>
    <Navbar/>
    <PageWrapper>
      { userType === userTypeConstant.TRADER ? <TraderOverview/> : <DistributorOverview/>}
    </PageWrapper>
  </>;
};

export default Home;