import axios from "axios";

const axiosClient = axios.create({
  baseURL: "https://loyapp.akit.rs/api/webuser",
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": "true",
  },
});

export default axiosClient;