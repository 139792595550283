import Navbar from "../components/UI/Navigation/Navbar";
import PageWrapper from "../components/UI/PageWrapper";
import ArticlesOverview from "../components/Trader/Articles/ArticlesOverview";

function Articles() {
  return (
    <>
      <Navbar/>
      <PageWrapper>
        <ArticlesOverview />
      </PageWrapper>
    </>
  );
}

export default Articles;