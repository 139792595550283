import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { traderService } from "../../services/traderService";
import { Grid, Typography } from "@material-ui/core";
import TraderDashCard from "./TraderDashCard";
import LoadingProgress from "../UI/LoadingProgress";

const TraderOverview = () => {
  const classes = useStyles();
  const [traderData, setTraderData] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    (async () => {
      const result = await traderService.getStoreDashAsync();
      if (result.success) {
        setTraderData(result.data);
        console.log(result.data);
      } else {
        console.log("store dash failed");
      }
      setLoading(false);
    })();
  }, []);
  
  if (loading) {
    return <LoadingProgress />
  }
  
  return <>
    <Typography variant="h4">Pregled</Typography>
    {/*Danas*/}
    <Grid
      container
      direction="column">
      <Grid
        item
        className={classes.sectionTitle}>
        <Typography variant="h6">Danas</Typography>
      </Grid>
      <Grid
        item
        container
        spacing={5}>
        <Grid item>
          <TraderDashCard
            title={"Iznos"}
            value={traderData?.Iznos_danas}/>
        </Grid>
        <Grid item>
          <TraderDashCard
            title={"Broj računa"}
            value={traderData?.broj_racuna_danas}/>
        </Grid>
        <Grid item>
          <TraderDashCard
            title={"Broj loyalty"}
            value={traderData?.broj_loy_danas}/>
        </Grid>
        <Grid item>
          <TraderDashCard
            title={"Sakupljeno"}
            value={traderData?.sakupljeno_danas}/>
        </Grid>
        <Grid item>
          <TraderDashCard
            title={"Potrošeno"}
            value={traderData?.potroseno_danas}/>
        </Grid>
      </Grid>
    </Grid>
    {/*7 dana*/}
    <Grid
      item
      className={classes.sectionTitle}>
      <Typography variant="h6">7 dana</Typography>
    </Grid>
    <Grid
      item
      container
      spacing={5}>
      <Grid item>
        <TraderDashCard
          title={"Iznos"}
          value={traderData?.Iznos_sedam_dana}/>
      </Grid>
      <Grid item>
        <TraderDashCard
          title={"Broj računa"}
          value={traderData?.broj_racuna_sedam_dana}/>
      </Grid>
      <Grid item>
        <TraderDashCard
          title={"Broj loyalty"}
          value={traderData?.broj_loy_sedam_dana}/>
      </Grid>
      <Grid item>
        <TraderDashCard
          title={"Sakupljeno"}
          value={traderData?.sakupljeno_sedam_dana}/>
      </Grid>
      <Grid item>
        <TraderDashCard
          title={"Potrošeno"}
          value={traderData?.potroseno_sedam_dana}/>
      </Grid>
    </Grid>
  </>;
};

const useStyles = makeStyles((theme) => ({
  overviewSection: {
    marginTop: "1rem"
  },
  sectionTitle: {
    marginBottom: "1rem",
    marginTop: "1rem"
  }
}));

export default TraderOverview;