import { makeStyles, Modal, Box, Typography, Grid } from "@material-ui/core";

const PopupModal = ({
  modalOpen,
  toggleModalOpen,
  title,
  children,
  ...rest
}) => {
  const classes = useStyles();
  
  return (
    <>
      <Modal
        open={modalOpen}
        onClose={toggleModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modal}>
          <Typography
            id="modal-modal-title"
            className={classes.title}
            variant="h6"
            component="h2">
            {title}
          </Typography>
          <Grid
            id="modal-modal-description"
            sx={{mt: 2}}>
            {children({toggleModalOpen, ...rest})}
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    width: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: 20,
    boxShadow: 10,
    borderRadius: "5px",
    p: 4,
    overflowY:'auto',
    maxHeight:'90%',
  },
  title: {
    textAlign: "center",
  }
}));

export default PopupModal;
