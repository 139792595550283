import { userApi } from "../api/endpoints/userApi";
import { webResult } from "../helpers/webResult";

const loginAsync = async (data) => {
  try {
    const response = await userApi.login(data);
    localStorage.setItem("currentUser", JSON.stringify(response.data));
    console.log(response.data);
    return webResult(true);
  } catch (e) {
    return webResult(false);
  }
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("currentUser"));
};

const isLoggedIn = () => {
  return localStorage.getItem("currentUser") !== null;
};

const logoutAsync = async () => {
  try {
    const { token } = userService.getCurrentUser();
    await userApi.logout(token);
    localStorage.removeItem("currentUser");
    return webResult(true);
  } catch (e) {
    return webResult(false);
  }
}

export const userService = {
  loginAsync,
  getCurrentUser,
  logoutAsync,
  isLoggedIn,
};