import axiosClient from "../apiClient";

function login(data) {
  return axiosClient.get("check", {
    headers: {
      "Username" : data.username,
      "Password" : data.password
    }
  });
}

function logout(token) {
  return axiosClient.get("logoff", {
    headers: {
      "Token" : token,
    }
  });
}

export const userApi = {
  login,
  logout,
};
